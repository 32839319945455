body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media(min-width:1100px){
  .outer {
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100%;

    }
    .inner{

    width: 100%;
    margin: auto;
    height: 100%;
    }




    h1,h2,h3,h4,h5,h6{
      margin: 0;
    }
    h5{
      margin: 0;
      font-weight: 500;
      font-size: 1.25rem;
    }
    h6{
      font-weight: 500;
      font-size: 1rem;
    }
    .card{
    padding: 15px;
    max-width: 1265px;
    margin: auto;
    min-height: 1100px;
    background-color: ghostwhite;
    box-shadow: 0 0px 20px 0 rgb(0 0 0 / 15%), 0 0px 20px 0 rgb(0 0 0 / 15%);
    }
    .card2{
      padding: 15px;
      max-width: 1265px;
      margin: auto;
      min-height: 1100px;
      background-color: ghostwhite;
      box-shadow: 0 0px 20px 0 rgb(0 0 0 / 15%), 0 0px 20px 0 rgb(0 0 0 / 15%);
      margin-bottom: 25px;
    }
    .header{
      display: flex;
      justify-content: center;
    }
    .alert{
      display: flex;
      justify-content: center;
      margin-top: 5px;
    }
    .label{
      display: flex;
      justify-content: center;
      margin-bottom: 5px;
      font-size: small
    }
    .doctorStatus{
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    select{
      height: 40px;
      width: 100%;
       outline-color: lightblue;
       color: #495057;
       font-size: 1rem;
       background-color: #fff;
       border-radius: 5px;
       padding: 5px;
       border: 1px solid #ced4da;
    }
    .select-validation{
      height: 40px;
      width: 100%;
       outline-color: lightblue;
       color: #495057;
       font-size: 1rem;
       background-color: #fff;
       border-radius: 5px;
       padding: 5px;
       border: 1px solid red;
    }
    select:focus{
      border-color: lightblue;
    }
    select:hover{
      border-color: lightblue;
      box-shadow: lightblue;
      transition: 0.2s ease;
    }
    .inputs
    {
      display: flex;
      flex-direction: row;
      margin-top: 25px;
      align-items: center;

    }
    .input-normal
    {
      height: 25px;
      outline-color: lightblue;
      font-size: 1rem;
      background-color: #fff;
      border: 1px solid #ced4da;
      border-radius: 5px;
      font-size: 1rem;
      color: #495057;
      width: 100%;
      min-width: 219px;
      padding: 5px;
    }
    .input-normal-email
    {
      height: 25px;
      outline-color: lightblue;
      font-size: 1rem;
      background-color: #fff;
      border: 1px solid #ced4da;
      border-radius: 5px;
      font-size: 1rem;
      color: #495057;

      min-width: 219px;
      padding: 5px;
    }
    .input-normal-validation
    {
      height: 25px;
      outline: none;
      font-size: 1rem;
      background-color: #fff;
      border: 1px solid red;
      border-radius: 5px;
      font-size: 1rem;
      color: #495057;
      width: 100%;
      min-width: 219px;
      padding: 5px;
    }
    .input-normal-validation-email
    {
      height: 25px;
      outline: none;
      font-size: 1rem;
      background-color: #fff;
      border: 1px solid red;
      border-radius: 5px;
      font-size: 1rem;
      color: #495057;

      min-width: 219px;
      padding: 5px;
    }
    .input-normal:hover{
      border-color: lightblue;
      box-shadow: lightblue;
      transition: 0.3s ease;
    }
    .input-normal-email:hover{
      border-color: lightblue;
      box-shadow: lightblue;
      transition: 0.3s ease;
    }
    .input-label{
      height: max-content;
      width: 100%;
      font-size: small;
    }
    .input-label-column{
      height: max-content;
      width: 100%;
      font-size: small;
    }
    .labelContainer{
      display: flex;
      text-align: center;
      margin-bottom: 10px;

    }
    .inputContainer{
      margin-right: 20px;
    }
    .information{
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      margin-left: 20px;
    }
    .checkboxContainer-column{
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .checkboxContainer-row{
      margin-top: 20px;
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      align-items: center;
      justify-content: start;
    }

    .input-label-row{
      height: max-content;
      width: 100%;
      font-size: small;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
    }
    .labelContainer-row{
      display: flex;
      text-align: center;

    }
}

@media(max-width:1100px){
  .input-normal-validation
    {
      height: 25px;
      outline: none;
      font-size: 1rem;
      background-color: #fff;
      border: 1px solid red;
      border-radius: 5px;
      font-size: 1rem;
      color: #495057;

      min-width: 219px;
      padding: 5px;
    }
    .input-normal-validation-email
    {
      height: 25px;
      outline: none;
      font-size: 1rem;
      background-color: #fff;
      border: 1px solid red;
      border-radius: 5px;
      font-size: 1rem;
      color: #495057;

      min-width: 219px;
      padding: 5px;
    }
  .select-validation{
    height: 40px;

     outline-color: lightblue;
     color: #495057;
     font-size: 1rem;
     background-color: #fff;
     border-radius: 5px;
     padding: 5px;
     border: 1px solid red;
  }

h1,h2,h3,h4,h5,h6{
  font-weight: 550;
}

  body{
    background-color: ghostwhite;
  }

  .card{
    padding: 15px;
    }
    .card2{
      padding: 15px;
    }
   
    .header{
      display: flex;
      justify-content: center;
    }
    .alert{
      display: flex;
      justify-content: center;
      margin-top: 5px;
    }
    .label{
      display: flex;
      justify-content: center;
      margin-bottom: 5px;
      font-size: small
    }
    .doctorStatus{
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

    }
    select{
       height: 40px;
       text-align: center;
       outline-color: lightblue;
       color: #495057;
       font-size: 1rem;
       background-color: #fff;
       border-radius: 5px;
       padding: 5px;
       border: 1px solid #ced4da;
    }
    select:focus{
      border-color: lightblue;
    }
    select:hover{
      border-color: lightblue;
      box-shadow: lightblue;
      transition: 0.2s ease;
    }
    .input-label-row{
      height: max-content;
      width: 100%;
      font-size: small;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
    }
    .labelContainer-row{
      display: flex;
      text-align: center;

    }
    .checkboxContainer-row{
      margin-top: 20px;
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      align-items: center;
      justify-content: start;
      margin-bottom: 20px;
      font-size: small;
    }
    .checkboxContainer-column{
      margin-top: 20px;
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      align-items: center;
      justify-content: start;
      margin-bottom: 20px;
      font-size: small;

    }
    .labelContainer{
      display: flex;
      text-align: center;


    }
    .input-normal
    {
      height: 25px;
      outline-color: lightblue;
      font-size: 1rem;
      background-color: #fff;
      border: 1px solid #ced4da;
      border-radius: 5px;
      color: #495057;
      padding: 5px;
    }
    .input-normal-email
    {
      height: 25px;
      outline-color: lightblue;
      font-size: 1rem;
      background-color: #fff;
      border: 1px solid #ced4da;
      border-radius: 5px;
      color: #495057;
      padding: 5px;
    }
    .input-normal:hover{
      border-color: lightblue;
      box-shadow: lightblue;
      transition: 0.3s ease;
    }
    .input-norma-email:hover{
      border-color: lightblue;
      box-shadow: lightblue;
      transition: 0.3s ease;
    }
    .input-label{
      height: max-content;
      width: 100%;
      font-size: small;
      margin-bottom: 5px;

    }
    .inputContainer{
      margin-top: 15px;
      display: flex;
      flex-direction: column;

    }
    .header{
      font-size: large;
    }
    .information{
      font-size: large;

    }
    .input-label-column{
      height: max-content;
      width: 100%;
      font-size: small;
      margin-left: 20px;
    }



}

.input-label-information{
  height: max-content;
  width: 100%;
  font-size: small;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
}
.button{
  background-color: lightskyblue;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  width: 120px;
  height: 35px;
  font-weight: 500;
}
.buttondiv{
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  margin-top:50px;
}
.payment{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.button-space{
  display: flex;
  justify-content: center;
  align-items: center;
}
.button:hover{
 background-color: rgb(103, 179, 204);
 color: white;
 transition: 0.3s ease;
}
.descryption{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.header2{
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}
.text-aligin-center{
  text-align: center;
}
.list{
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
}
.margin{
  margin-top: 5px;
  margin-bottom: 5px;
}
.header-space{
  margin-top: 30px;
  margin-bottom: 20px;
}
.red{
  color: red;
  
}
.marginTop{
  margin-top: 60px;
}
